import React from 'react';
import { contact, section5Title, social } from '../../profile';
import '../../styles/style.css'

const Contact = () => {
  return (
    <div className='parallax' id='not-dark4'>
      <div
        data-aos='zoom-in-up'
        data-aos-once='true'
        className='git-form'
        id='form'
      >
        <>
          <div className='git-head-div text-center mx-auto'>
            <h1 id='Contact' className='git-head'>
              {section5Title}
            </h1>
          </div>
        </>
        <div className='container'>
          <div className='git-cont row'>
            <div className='col-12 col-sm-6 half'>
              <form
                action={
                  contact.contactUrl
                    ? contact.contactUrl
                    : 'https://formspree.io/f/mlezdedd'
                }
                method={'POST'}
              >
                <input
                  type='text'
                  id='fname'
                  name='firstname'
                  placeholder='Your name'
                  required
                ></input>
                <input
                  type='mail'
                  id='mailid'
                  name='Email'
                  placeholder='Email Address'
                  required
                ></input>
                <input
                  type='text'
                  id='sub'
                  name='Subject'
                  placeholder='Subject'
                  required
                ></input>
                <textarea
                  id='msg'
                  name='message'
                  placeholder='Message'
                  required
                ></textarea>
                <button style={{ cursor: 'pointer' }} type='submit'>
                  Send Message
                </button>
              </form>
            </div>
            <div className='col-12 col-sm-6 half'>
              <p className='lead'>{contact.pitch}</p>
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <div className='inline-block'>
                  {social.linkedin && (
                    <a
                      title='Visit Linkedin profile'
                      rel='noopener noreferrer'
                      target='_blank'
                      href={social.linkedin}
                    >
                      <i className='fab fa-linkedin'></i>
                    </a>
                  )}
                  {social.facebook && (
                    <a
                      title='Visit Facebok profile'
                      rel='noopener noreferrer'
                      target='_blank'
                      href={social.facebook}
                    >
                      <i className='fab fa-facebook'></i>
                    </a>
                  )}
                  {social.twitter && (
                    <a
                      title='Visit Twitter profile'
                      rel='noopener noreferrer'
                      target='_blank'
                      href={social.twitter}
                    >
                      <i className='fab fa-twitter'></i>
                    </a>
                  )}
                  {social.instagram && (
                    <a
                      title='Visit Instagram profile'
                      rel='noopener noreferrer'
                      target='_blank'
                      href={social.instagram}
                    >
                      <i className='fab fa-instagram'></i>
                    </a>
                  )}
                  {social.github && (
                    <a
                      title='Visit Github profile'
                      rel='noopener noreferrer'
                      target='_blank'
                      href={social.github}
                    >
                      <i className='fab fa-github'></i>
                    </a>
                  )}
                  <br />
                </div>
                <div style={{ marginTop: '70px' }}>Click below for Resume</div>
                {social.resume && (
                  <a title='Download Resume' href={social.resume} download>
                    <i
                    
                      className='fas fa-download'
                      id="download-link"
                    ></i>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <p id='not-dark3' className='Copy'>
        {new Date().getFullYear()} © Copyright{' '}
        <strong>{contact.copyright}</strong>. All Rights Reserved
      </p>
    </div>
  );
};

export default Contact;
