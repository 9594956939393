import React from 'react';
import Project from '../layouts/Project';
import {
  projects,
  miscellaneous,
  section3Title,
  section4Title,
} from '../../profile';

const Works = () => {
  return (
    <>
      <div data-aos='zoom-in-up' data-aos-once='true' className='third'>
        <>
          <div className='pp-head-line mx-auto text-center'>
            <h1 id='Projects' className='red-line pp-head'>
              {section3Title}
            </h1>
          </div>
        </>
        <div className='row'>
          {projects &&
            projects.map((x) => (
              <Project
                key={x.id}
                id={x.id}
                url={x.url}
                name={x.name}
                url_github_frontend ={x.url_github_frontend}
                url_github_backend ={x.url_github_backend}
              />
            ))}
        </div>
      </div>

      <div className='third'>
        <>
          <div className='pp-head-line mx-auto text-center'>
            <h1 id='Projects' className='red-line pp-head'>
              {section4Title} <i className='fab fa-github'></i>
            </h1>
          </div>
        </>
        <div className='row'>
          {miscellaneous &&
            miscellaneous.map((x) => (
              <Project
                key={x.id}
                id={x.id}
                url={x.url}
                name={x.name}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default Works;
